var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title',[_vm._v("Users")]),(_vm.$can('__special_managePermission'))?_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.$refs.createUserModal.showModal()}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Add User")]),_c('create-user-modal',{ref:"createUserModal",attrs:{"impersonate-as-tenant-id":_vm.tenantId},on:{"userCreated":_vm.onUserCreated}})],1):_vm._e()],1),_c('b-card-body',[(_vm.$can('Core.Users') === false)?_c('p',{staticClass:"text-warning"},[_vm._v(" No access to view this data. ")]):_vm._e(),(_vm.$can('Core.Users'))?_c('Grid',{attrs:{"data-items":_vm.result,"columns":_vm.columns,"column-menu":true,"take":_vm.dataState.take,"skip":_vm.dataState.skip,"sort":_vm.dataState.sort,"filter":_vm.dataState.filter,"group":_vm.dataState.group,"expand-field":'expanded',"sortable":true,"reorderable":true,"resizable":true,"groupable":false,"pageable":true,"filterable":true,"page-size":50},on:{"datastatechange":_vm.onDataStateChange,"filterchange":_vm.onFilterChange,"sortchange":_vm.onSortChange,"columnreorder":_vm.onColumnReorder},scopedSlots:_vm._u([{key:"cellActionTemplate",fn:function(ref){
var props = ref.props;
return [_c('DetailLinkActionCellTemplate',{attrs:{"field":props.field,"row-type":props.rowType,"class-name":props.className,"to":_vm.isHost ? 'host:manage:tenant-user-detail' : 'tenant:manage:tenant-user-detail',"to-params":{ id: _vm.tenantId, userid: props.dataItem.id}}})]}},{key:"cellProfileImage",fn:function(ref){
var props = ref.props;
return [_c('UserProfileImageCellTemplate',{attrs:{"data-item":props.dataItem,"field":props.field,"row-type":props.rowType,"class-name":props.className}})]}},{key:"ouListTemplate",fn:function(ref){
var props = ref.props;
return [_c('ListCellTemplate',{attrs:{"list-items":_vm.getOrganizationUnitNames(props.dataItem.organizationUnits),"field":props.field,"row-type":props.rowType,"class-name":props.className}})]}},{key:"roleListTemplate",fn:function(ref){
var props = ref.props;
return [_c('ListCellTemplate',{attrs:{"list-items":_vm.getRoleNames(props.dataItem.roles),"field":props.field,"row-type":props.rowType,"class-name":props.className}})]}}],null,false,1563268786)},[_c('GridToolbar',[_c('toolbar-item-view-manager',{attrs:{"grid-id":_vm.gridId,"columns":_vm.columns,"data-state":_vm.dataState,"original-columns":_vm.originalColumns,"original-data-state":_vm.originalDataState,"current-view-name":_vm.currentViewName,"current-view-id":_vm.currentViewId},on:{"resetToDefaultViewClick":_vm.resetToDefaultView,"applyView":_vm.onApplyView}}),_c('toolbar-item-column-manager',{attrs:{"columns":_vm.activeColumns,"original-columns":_vm.originalColumns},on:{"columnssubmit":function($event){_vm.columns = $event}}}),_c('toolbar-item-export-excel',{attrs:{"data-items":_vm.dataItems,"columns":_vm.activeColumns,"data-state":_vm.dataState}}),_c('toolbar-item-loading-indicator',{attrs:{"is-loading":_vm.isLoading}})],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }