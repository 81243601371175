<template>
  <div>
    <b-card
      no-body
    >
      <b-card-header>
        <b-card-title>Users</b-card-title>

        <b-button
          v-if="$can('__special_managePermission')"
          variant="primary"
          size="sm"
          @click="$refs.createUserModal.showModal()"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">Add User</span>

          <create-user-modal
            ref="createUserModal"
            :impersonate-as-tenant-id="tenantId"
            @userCreated="onUserCreated"
          />

        </b-button>
      </b-card-header>

      <b-card-body>
        <p
          v-if="$can('Core.Users') === false"
          class="text-warning"
        >
          No access to view this data.
        </p>

        <Grid
          v-if="$can('Core.Users')"
          :data-items="result"
          :columns="columns"
          :column-menu="true"
          :take="dataState.take"
          :skip="dataState.skip"
          :sort="dataState.sort"
          :filter="dataState.filter"
          :group="dataState.group"
          :expand-field="'expanded'"
          :sortable="true"
          :reorderable="true"
          :resizable="true"
          :groupable="false"
          :pageable="true"
          :filterable="true"
          :page-size="50"
          @datastatechange="onDataStateChange"
          @filterchange="onFilterChange"
          @sortchange="onSortChange"
          @columnreorder="onColumnReorder"
        >
          <!-- Custom toolbar -->
          <GridToolbar>

            <toolbar-item-view-manager
              :grid-id="gridId"
              :columns="columns"
              :data-state="dataState"
              :original-columns="originalColumns"
              :original-data-state="originalDataState"
              :current-view-name="currentViewName"
              :current-view-id="currentViewId"
              @resetToDefaultViewClick="resetToDefaultView"
              @applyView="onApplyView"
            />

            <toolbar-item-column-manager
              :columns="activeColumns"
              :original-columns="originalColumns"
              @columnssubmit="columns = $event"
            />

            <toolbar-item-export-excel
              :data-items="dataItems"
              :columns="activeColumns"
              :data-state="dataState"
            />

            <toolbar-item-loading-indicator
              :is-loading="isLoading"
            />
          </GridToolbar>

          <!-- Custom action cell -->
          <template v-slot:cellActionTemplate="{ props }">
            <DetailLinkActionCellTemplate
              :field="props.field"
              :row-type="props.rowType"
              :class-name="props.className"
              :to="isHost ? 'host:manage:tenant-user-detail' : 'tenant:manage:tenant-user-detail'"
              :to-params="{ id: tenantId, userid: props.dataItem.id}"
            />
          </template>

          <!-- Profile image cell -->
          <template v-slot:cellProfileImage="{ props }">
            <UserProfileImageCellTemplate
              :data-item="props.dataItem"
              :field="props.field"
              :row-type="props.rowType"
              :class-name="props.className"
            />
          </template>

          <!-- OU list cell -->
          <template v-slot:ouListTemplate="{ props }">
            <ListCellTemplate
              :list-items="getOrganizationUnitNames(props.dataItem.organizationUnits)"
              :field="props.field"
              :row-type="props.rowType"
              :class-name="props.className"
            />
          </template>

          <!-- Role list cell -->
          <template v-slot:roleListTemplate="{ props }">
            <ListCellTemplate
              :list-items="getRoleNames(props.dataItem.roles)"
              :field="props.field"
              :row-type="props.rowType"
              :class-name="props.className"
            />
          </template>
        </Grid>
      </b-card-body>
    </b-card>

  </div>
</template>
<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BButton,
} from 'bootstrap-vue'
import { process } from '@progress/kendo-data-query'
import { Grid, GridToolbar } from '@progress/kendo-vue-grid'

import { mapGetters } from 'vuex'
import {
  ListCellTemplate, DetailLinkActionCellTemplate, UserProfileImageCellTemplate, ToolbarItemViewManager, ToolbarItemColumnManager, ToolbarItemExportExcel, ToolbarItemLoadingIndicator,
} from '@/components/grid'
import {
  GridDefaultMixin, GridPreserveStateMixin,
} from '@/mixins/grid'

import CreateUserModal from './CreateUserModal.vue'
import UserService from '@/service/user.service'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BButton,
    Grid,
    GridToolbar,
    CreateUserModal,
    ListCellTemplate,
    DetailLinkActionCellTemplate,
    UserProfileImageCellTemplate,
    ToolbarItemExportExcel,
    ToolbarItemColumnManager,
    ToolbarItemViewManager,
    ToolbarItemLoadingIndicator,
  },
  mixins: [
    GridDefaultMixin,
    GridPreserveStateMixin,
  ],
  props: {
    tenantId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      gridId: 'tenantdetail_user',
      columns: [
        {
          cell: 'cellActionTemplate',
          field: 'cellActionTemplate',
          title: ' ',
          locked: true,
          filterable: false,
          sortable: false,
          groupable: false,
          width: '40px',
          orderIndex: 0,
          static: true,
          columnMenu: false,
        },
        {
          cell: 'cellProfileImage',
          title: ' ',
          orderIndex: 1,
          width: '70px',
          filterable: false,
          sortable: false,
          groupable: false,
          columnMenu: false,
        },
        {
          field: 'name',
          title: 'Firstname',
          orderIndex: 2,
          width: '200px',
        },
        {
          field: 'surname',
          title: 'Lastname',
          orderIndex: 3,
          width: '200px',
        },
        {
          field: 'email',
          title: 'Email',
          orderIndex: 4,
          width: '400px',
        },
        {
          field: 'isActive',
          title: 'Active',
          orderIndex: 5,
          width: '100px',
          filter: 'boolean',
          type: 'boolean',
          columnMenu: false,
        },
        {
          cell: 'ouListTemplate',
          title: 'Organization units',
          orderIndex: 6,
          width: '200px',
          filterable: false,
          sortable: false,
          groupable: false,
          columnMenu: false,
        },
        {
          cell: 'roleListTemplate',
          title: 'Roles',
          orderIndex: 7,
          width: '200px',
          filterable: false,
          sortable: false,
          groupable: false,
          columnMenu: false,
        },
      ],
    }
  },
  computed: {
    result() {
      return process(this.dataItems, this.dataState)
    },
    ...mapGetters({
      isHost: 'auth/isHost',
    }),
  },
  created() {
    this.loadData()
  },
  methods: {
    async loadData() {
      this.isLoading = true

      if (this.isHost) {
        this.dataItems = (await UserService.getAllListAsync({ impersonateAsTenant: this.tenantId }))
      } else {
        this.dataItems = (await UserService.getListByAccessableOusAsync({ maxResultCount: 1000 }, { impersonateAsTenant: this.tenantId })).data.items
      }
      this.isLoading = false
    },
    getOrganizationUnitNames(organizationUnits) {
      if (organizationUnits) {
        return organizationUnits.map(x => x.displayName)
      }

      return []
    },
    getRoleNames(roles) {
      if (roles) {
        const self = this
        return roles.map(x => self.$options.filters.renameRoleName(x.name))
      }

      return []
    },
    onUserCreated() {
      this.loadData()
    },
  },
}
</script>
