<template>
  <div>
    <validation-observer
      ref="observer"
      v-slot="{ handleSubmit }"
    >
      <b-form
        @submit.stop.prevent="handleSubmit(onSubmit)"
      >
        <b-modal
          id="edit-ou-modal"
          ref="edit-ou-modal"
          v-model="isShow"
          title="Edit organization unit"
          no-close-on-backdrop
          scrollable
          @ok="save"
        >
          <b-overlay :show="isLoading || isSaving">

            <validation-provider
              v-slot="validationContext"
              name="Name"
              :rules="{ required: true, max: 128 }"
            >
              <b-form-group
                label="Name:"
                label-for="input-name"
              >
                <b-form-input
                  id="input-name"
                  v-model="form.displayName"
                  name="input-name"
                  placeholder="Enter name"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-name-live-feedback"
                />
                <b-form-invalid-feedback id="input-name-live-feedback">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-form-group
              description="Unset this checkbox to select a parent organization unit."
            >
              <b-form-checkbox
                v-model="asFirstLevel"
              >
                First level
              </b-form-checkbox>
            </b-form-group>

            <b-form-group
              v-if="!asFirstLevel"
              label="Parent:"
              label-for="input-parent"
            >
              <v-jstree
                :data="getOusTreeview()"
                show-checkbox
                size="large"
                whole-row
                text-field-name="displayName"
                value-field-name="id"
                children-field-name="children"
                @item-click="itemClick"
              />

            </b-form-group>
          </b-overlay>

        </b-modal>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>

import {
  BModal, BForm, BFormInput, BFormCheckbox, BFormGroup, BFormInvalidFeedback, BOverlay,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import {
  // eslint-disable-next-line no-unused-vars
  required, email, max, mapServerFieldValidationErrors,
} from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import VJstree from 'vue-jstree'

import OuService from '@/service/ou.service'

export default {
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormCheckbox,
    BFormGroup,
    BFormInvalidFeedback,
    ValidationObserver,
    ValidationProvider,
    BOverlay,
    VJstree,
  },
  props: {
    impersonateAsTenantId: {
      type: String,
      default: null,
    },
    organizationUnits: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isShow: false,
      isLoading: false,
      isSaving: false,
      ou: null,
      form: {
        displayName: '',
        concurrencyStamp: '',
      },
      asFirstLevel: true,
      selectedOu: null,
    }
  },
  computed: {
    selectedParentId() {
      if (this.asFirstLevel === true) {
        return null
      }

      return this.selectedOu.id
    },
  },
  methods: {
    getOusTreeview() {
      // Unflatten array
      // https://stackoverflow.com/a/55241491
      const nest = (items, id = null) => items
        .filter(item => item.parentId === id)
        .map(item => ({ ...item, children: nest(items, item.id), opened: true }))

      return nest(this.organizationUnits)
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    show(ouId) {
      this.isShow = true
      this.isLoading = true

      OuService.getAsync(ouId, { impersonateAsTenant: this.impersonateAsTenantId }).then(result => {
        this.ou = result.data

        this.form = {
          displayName: this.ou.displayName,
          concurrencyStamp: this.ou.concurrencyStamp,
        }

        if (this.ou.parentId === null) {
          this.asFirstLevel = true
        } else {
          this.asFirstLevel = false

          for (let i = 0; i < this.organizationUnits.length; i += 1) {
            if (this.organizationUnits[i].id === this.ou.parentId) {
              this.organizationUnits[i].selected = true
              this.selectedOu = this.organizationUnits[i]
            } else {
              this.organizationUnits[i].selected = false
            }

            if (this.organizationUnits[i].id === this.ou.id) {
              this.organizationUnits[i].disabled = true
            } else {
              this.organizationUnits[i].disabled = false
            }
          }
        }

        this.isLoading = false
      })
    },
    itemClick(node) {
      this.selectedOu = node.model
    },
    async save(event) {
      event.preventDefault()

      const self = this
      this.isSaving = true

      const requestConfig = {
        impersonateAsTenant: this.impersonateAsTenantId,
      }

      if (this.asFirstLevel === false && this.selectedOu === null) {
        self.$swal({
          title: 'Please select a parent organization unit.',
          icon: 'warning',
        })

        return
      }

      try {
        await OuService.updateAsync(this.ou.id, this.form, requestConfig)
        const response = await OuService.moveAsync(this.ou.id, { parentId: this.selectedParentId }, requestConfig)

        self.$emit('ouUpdated', {
          ou: response.data,
        })

        self.$toast.success('Organization unit has been updated.', {
          icon: true,
        })

        self.isShow = false
      } catch (e) {
        const errors = mapServerFieldValidationErrors(e.response, {
          displayName: 'DisplayName',
        })

        self.$refs.observer.setErrors(errors)

        self.$swal({
          title: 'Something went wrong!',
          text: e.response.data.error.message,
          icon: 'error',
        }).then(() => {
          self.isShow = true
        })
      } finally {
        self.isSaving = false
      }
    },
  },
}
</script>
