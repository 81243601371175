<template>
  <div>

    <b-row class="mb-2">
      <b-col>
        <h2>{{ userProfile.tenantName }}</h2>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <tenant-detail-ou-card :tenant-id="tenantId" />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <tenant-detail-role-card :tenant-id="tenantId" />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <tenant-detail-user-card :tenant-id="tenantId" />
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BRow, BCol,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import TenantDetailOuCard from '@/views/shared/tenant/TenantDetailOuCard.vue'
import TenantDetailRoleCard from '@/views/shared/tenant/TenantDetailRoleCard.vue'
import TenantDetailUserCard from '@/views/shared/tenant/TenantDetailUserCard.vue'

export default {
  components: {
    BRow,
    BCol,
    TenantDetailOuCard,
    TenantDetailRoleCard,
    TenantDetailUserCard,
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      userProfile: 'auth/userProfile',
    }),
    tenantId() {
      return this.userProfile.tenantId
    },
  },
}

</script>
