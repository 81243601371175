<template>
  <div>
    <div
      v-for="permission in permissions"
      :key="permission.name"
      class="ml-2"
    >
      <b-form-checkbox
        v-model="permission.isGranted"
        :class="{ 'ml-2': permission.parentName !== null, 'mt-1': permission.parentName === null }"
      >
        {{ permission.displayName }}
      </b-form-checkbox>
    </div>
  </div>
</template>

<script>

import { BFormCheckbox } from 'bootstrap-vue'
import RoleService from '@/service/role.service'

export default {
  components: {
    BFormCheckbox,
  },
  props: {
    role: {
      type: String,
      default: null,
    },
    impersonateAsTenantId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      permissions: [],
      originalPermissions: [],
    }
  },
  watch: {
    role() {
      this.loadData()
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      RoleService.getRolePermissions(this.role, { impersonateAsTenant: this.impersonateAsTenantId }).then(result => {
        this.permissions = [].concat(...result.data.groups.map(x => x.permissions))
        this.originalPermissions = JSON.parse(JSON.stringify(this.permissions))

        this.$emit('loaded')
      })
    },
    getChangedPermissionGrants() {
      const changedPermissions = []
      for (let p = 0; p < this.permissions.length; p += 1) {
        const permission = this.permissions[p]
        for (let o = 0; o < this.originalPermissions.length; o += 1) {
          const originalPermission = this.originalPermissions[o]

          if (permission.name === originalPermission.name && permission.isGranted !== originalPermission.isGranted) {
            changedPermissions.push({
              name: permission.name,
              isGranted: permission.isGranted,
            })
          }
        }
      }

      return changedPermissions
    },
  },
}
</script>
