export default {
  createDefaultDataState() {
    return {
      take: 50,
      skip: 0,
      filter: null,
      group: null,
      sort: null,
    }
  },
  createView(columns, dataState, displayName, id) {
    const view = {
      id,
      displayName,
      dataState,
      columns: columns.map(c => ({
        field: c.field,
        orderIndex: c.orderIndex,
        locked: c.locked,
        width: c.width,
        hidden: c.hidden,
      })),
    }

    return view
  },
  highlightColumnsWithSortOrFilter(columns, dataState) {
    for (let c = 0; c < columns.length; c += 1) {
      const column = columns[c]
      if (column.field !== null && typeof column.field !== 'undefined') {
        column.headerClassName = '' // reset

        if (dataState.sort !== null && typeof dataState.sort !== 'undefined') {
          for (let s = 0; s < dataState.sort.length; s += 1) {
            const sort = dataState.sort[s]
            if (sort.field === column.field) {
              column.headerClassName = 'active'
            }
          }
        }
        if (
          dataState.filter !== null
          && typeof dataState.filter !== 'undefined'
        ) {
          for (let f = 0; f < dataState.filter.filters.length; f += 1) {
            const filter = dataState.filter.filters[f]

            // filter can exist in two ways. One with a direct field in that object, the other with an array of filters in that object
            if (filter.field && filter.field === column.field) {
              column.headerClassName = 'active'
            }

            if (filter.filters && filter.filters.some(x => x.field === column.field)) {
              column.headerClassName = 'active'
            }
          }
        }
      }
    }
  },
}
