// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'
import { buildAxiosRequestConfig } from './serviceManager'

export default {
  async getAllListAsync(config) {
    const response = await axios.get('/core/organization-unit/all', buildAxiosRequestConfig(config))
    return response
  },

  async getAsync(id, config) {
    const response = await axios.get(`/core/organization-unit/${id}`, buildAxiosRequestConfig(config))
    return response
  },

  async updateAsync(id, data, config) {
    const response = await axios.put(`/core/organization-unit/${id}`, data, buildAxiosRequestConfig(config))
    return response
  },

  async deleteAsync(id, config) {
    const response = await axios.delete(`/core/organization-unit/${id}`, buildAxiosRequestConfig(config))
    return response
  },

  async createAsync(data, config) {
    const response = await axios.post('/core/organization-unit', data, buildAxiosRequestConfig(config))
    return response
  },

  async moveAsync(id, data, config) {
    const response = await axios.put(`/core/organization-unit/${id}/move`, data, buildAxiosRequestConfig(config))
    return response
  },
}
