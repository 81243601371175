<template>
  <div>
    <validation-observer
      ref="observer"
      v-slot="{ handleSubmit }"
    >
      <b-form
        @submit.stop.prevent="handleSubmit(onSubmit)"
      >
        <b-modal
          id="create-role-modal"
          v-model="show"
          title="Create role"
          no-close-on-backdrop
          scrollable
          @show="resetModal"
          @ok="save"
        >
          <b-overlay :show="isLoading || isSaving">

            <validation-provider
              v-slot="validationContext"
              name="Name"
              :rules="{ required: true, max: 256 }"
            >
              <b-form-group
                label="Name:"
                label-for="input-name"
              >
                <b-form-input
                  id="input-name"
                  v-model="form.name"
                  name="input-name"
                  placeholder="Enter name"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-name-live-feedback"
                />
                <b-form-invalid-feedback id="input-name-live-feedback">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <role-permission-view
              ref="permissionView"
              :impersonate-as-tenant-id="impersonateAsTenantId"
              @loaded="isLoading = false"
            />
          </b-overlay>

        </b-modal>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>

import {
  BModal, BForm, BFormInput, BFormGroup, BFormInvalidFeedback, BOverlay,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import {
  // eslint-disable-next-line no-unused-vars
  required, email, max, mapServerFieldValidationErrors,
} from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RolePermissionView from './RolePermissionView.vue'
import RoleService from '@/service/role.service'

export default {
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormInvalidFeedback,
    ValidationObserver,
    ValidationProvider,
    RolePermissionView,
    BOverlay,
  },
  props: {
    impersonateAsTenantId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      show: false,
      isLoading: false,
      isSaving: false,
      form: {
        name: '',
      },
    }
  },
  created() {

  },
  methods: {
    resetModal() {
      this.isLoading = true
      this.isSaving = false

      this.form = {
        name: '',
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    async save(event) {
      event.preventDefault()

      const self = this
      this.isSaving = true

      const requestConfig = {
        impersonateAsTenant: this.impersonateAsTenantId,
      }

      // Get permission to update
      const permissions = self.$refs.permissionView.getChangedPermissionGrants()
      try {
        // Create role
        const response = await RoleService.createAsync(this.form, requestConfig)

        if (permissions.length > 0) {
        // Update permissions
          const updatePermissionsData = { permissions }
          await RoleService.updatePermissions(response.data.id, updatePermissionsData, requestConfig)
        }

        self.$emit('roleCreated', {
          role: response.data,
        })

        self.$toast.success('Role has been created.', {
          icon: true,
        })

        self.show = false
      } catch (e) {
        const errors = mapServerFieldValidationErrors(e.response, {
          name: 'Name',
        })

        self.$refs.observer.setErrors(errors)

        self.$swal({
          title: 'Something went wrong!',
          text: e.response.data.error.message,
          icon: 'error',
        }).then(() => {
          self.show = true
        })
      } finally {
        self.isSaving = false
      }
    },
  },
}
</script>
