// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'
import { buildAxiosRequestConfig } from './serviceManager'

export default {
  async getAllListAsync(config) {
    const response = await axios.get('/core/roles/all', buildAxiosRequestConfig(config))
    return response
  },

  async getRolePermissions(name, config) {
    const response = await axios.get(`/core/permission?providerName=R&providerKey=${name}`, buildAxiosRequestConfig(config))
    return response
  },

  async getAsync(id, config) {
    const response = await axios.get(`/core/roles/${id}`, buildAxiosRequestConfig(config))
    return response
  },

  async deleteAsync(id, config) {
    const response = await axios.delete(`/core/roles/${id}`, buildAxiosRequestConfig(config))
    return response
  },

  async createAsync(data, config) {
    const response = await axios.post('/core/roles', data, buildAxiosRequestConfig(config))
    return response
  },

  async updateAsync(id, data, config) {
    const response = await axios.put(`/core/roles/${id}`, data, buildAxiosRequestConfig(config))
    return response
  },

  async updatePermissions(roleId, data, config) {
    const response = await axios.put(`/core/roles/${roleId}/permissions`, data, buildAxiosRequestConfig(config))
    return response
  },
}
