<template>
  <div>
    <validation-observer
      ref="observer"
      v-slot="{ handleSubmit }"
    >
      <b-form
        @submit.stop.prevent="handleSubmit(onSubmit)"
      >
        <b-modal
          id="create-user-modal"
          v-model="show"
          title="Create user"
          no-close-on-backdrop
          scrollable
          @show="resetModal"
          @ok="save"
        >
          <b-overlay :show="isLoading || isSaving">
            <b-alert
              :show="showRoleIsMandatoryAlert"
              variant="warning"
            >
              At least one role is mandatory!
            </b-alert>

            <validation-provider
              v-slot="validationContext"
              name="Firstname"
              :rules="{ required: true, max: 256 }"
            >
              <b-form-group
                label="Firstname:"
                label-for="input-firstname"
              >
                <b-form-input
                  id="input-firstname"
                  v-model="form.firstname"
                  name="input-firstname"
                  placeholder="Enter Firstname"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-firstname-live-feedback"
                />
                <b-form-invalid-feedback id="input-firstname-live-feedback">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-slot="validationContext"
              name="Lastname"
              :rules="{ required: true, max: 256 }"
            >
              <b-form-group
                label="Lastname:"
                label-for="input-lastname"
              >
                <b-form-input
                  id="input-lastname"
                  v-model="form.lastname"
                  name="input-lastname"
                  placeholder="Enter Lastname"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-lastname-live-feedback"
                />
                <b-form-invalid-feedback id="input-lastname-live-feedback">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-slot="validationContext"
              name="Email"
              :rules="{ required: true, max: 256, email: true }"
            >
              <b-form-group
                label="E-Mail:"
                label-for="input-email"
              >
                <b-form-input
                  id="input-email"
                  v-model="form.email"
                  name="input-email"
                  placeholder="Enter E-Mail"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-email-live-feedback"
                />
                <b-form-invalid-feedback id="input-email-live-feedback">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <label class="d-block">Roles:</label>
            <b-form-checkbox-group v-model="form.roles">
              <ul style="list-style: none; padding-inline-start: 0">
                <li
                  v-for="role in roles"
                  :key="role.id"
                >
                  <b-form-checkbox :value="role.name">
                    {{ role.name | renameRoleName }}
                  </b-form-checkbox>
                </li>
              </ul>
            </b-form-checkbox-group>

            <template v-if="ous.length > 0">
              <label class="d-block">Organization Units:</label>
              <v-jstree
                :data="ousTreeview"
                show-checkbox
                size="large"
                whole-row
                text-field-name="displayName"
                value-field-name="id"
                children-field-name="children"
                multiple
              />
              <b-form-checkbox
                v-model="hasAccessToDefaultOu"
                switch
              >
                Has access to systems without Organization Unit
              </b-form-checkbox>
            </template>
          </b-overlay>

        </b-modal>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>

import {
  BModal, BForm, BFormInput, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BFormInvalidFeedback, BOverlay, BAlert,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import {
  // eslint-disable-next-line no-unused-vars
  required, email, max, mapServerFieldValidationErrors,
} from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import VJstree from 'vue-jstree'
import UserService from '@/service/user.service'
import RoleService from '@/service/role.service'
import OuService from '@/service/ou.service'

export default {
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormInvalidFeedback,
    ValidationObserver,
    ValidationProvider,
    BOverlay,
    VJstree,
    BAlert,
  },
  props: {
    impersonateAsTenantId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      show: false,
      isLoading: false,
      isSaving: false,
      form: {
        email: '',
        lastname: '',
        firstname: '',
        roles: [],
      },
      roles: [],
      ous: [],
      hasAccessToDefaultOu: false,
      showRoleIsMandatoryAlert: false,
    }
  },
  computed: {
    ousTreeview() {
      // Unflatten array
      // https://stackoverflow.com/a/55241491
      const nest = (items, id = null) => items
        .filter(item => item.parentId === id)
        .map(item => ({ ...item, children: nest(items, item.id), opened: true }))

      return nest(this.ous)
    },
  },
  methods: {
    showModal() {
      this.resetModal()
      this.show = true

      // load data
      this.isLoading = true
      Promise.all([
        RoleService.getAllListAsync({ impersonateAsTenant: this.impersonateAsTenantId }),
        OuService.getAllListAsync({ impersonateAsTenant: this.impersonateAsTenantId }),
      ]).then(result => {
        this.roles = result[0].data.items
        this.ous = result[1].data.items
      })
        .finally(() => {
          this.isLoading = false
        })
    },
    resetModal() {
      this.isLoading = false
      this.isSaving = false
      this.showRoleIsMandatoryAlert = false

      this.form = {
        firstname: '',
        lastname: '',
        email: '',
        roles: [],
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    async save(event) {
      event.preventDefault()

      const self = this
      this.isSaving = true

      const requestConfig = {
        impersonateAsTenant: this.impersonateAsTenantId,
      }

      try {
        const data = {
          name: this.form.firstname,
          surname: this.form.lastname,
          email: this.form.email,
          roleNames: this.form.roles,
        }

        if (data.roleNames.length === 0) {
          this.showRoleIsMandatoryAlert = true
          this.isSaving = false
          return
        }

        // Create user
        const userCreateResponse = await UserService.createAsync(data, requestConfig)

        // Update OUs
        const selectedOuIds = this.ousTreeview.filter(x => x.selected === true).map(x => x.id)
        if (selectedOuIds.length > 0) {
          try {
            await UserService.updateOusAsync(userCreateResponse.data.id, { ouIds: selectedOuIds }, { impersonateAsTenant: this.impersonateAsTenantId })
          } catch {
            self.$toast.error('Could not assign user to Organization units!', {
              icon: true,
            })
          }
        }

        // Set "has access to default ou"
        if (this.hasAccessToDefaultOu) {
          try {
            const userPermissionsResult = await UserService.getUserPermissionsAsync(userCreateResponse.data.id, { impersonateAsTenant: this.impersonateAsTenantId })
            const userPermissions = [].concat(...userPermissionsResult.data.groups.map(x => x.permissions))

            // find accessDefaultOu permission and update isGranted
            const accessToDefaultouPermission = userPermissions.find(x => x.name === 'Core.Users.AccessDefaultOu')
            accessToDefaultouPermission.isGranted = true

            // map to correct object structure for API
            const permissionsToUpdate = userPermissions.map(x => ({ name: x.name, isGranted: x.isGranted }))
            await UserService.updatePermissionsAsync(userCreateResponse.data.id, { permissions: permissionsToUpdate }, { impersonateAsTenant: this.impersonateAsTenantId })
          } catch {
            self.$toast.error('Could not grant permission to accees default organization unit!', {
              icon: true,
            })
          }
        }

        self.$emit('userCreated', {
          role: userCreateResponse.data,
        })

        self.$toast.success('User has been created.', {
          icon: true,
        })

        self.show = false
      } catch (e) {
        const errors = mapServerFieldValidationErrors(e.response, {
          name: 'Name',
        })

        self.$refs.observer.setErrors(errors)

        self.$swal({
          title: 'Something went wrong!',
          text: e.response.data.error.message,
          icon: 'error',
        }).then(() => {
          self.show = true
        })
      } finally {
        self.isSaving = false
      }
    },
  },
}
</script>
