<template>
  <div>
    <validation-observer
      ref="observer"
      v-slot="{ handleSubmit }"
    >
      <b-form
        @submit.stop.prevent="handleSubmit(onSubmit)"
      >
        <b-modal
          id="create-ou-modal"
          v-model="show"
          title="Create organization unit"
          no-close-on-backdrop
          scrollable
          @show="resetModal"
          @ok="save"
        >
          <b-overlay :show="isLoading || isSaving">

            <validation-provider
              v-slot="validationContext"
              name="Name"
              :rules="{ required: true, max: 128 }"
            >
              <b-form-group
                label="Name:"
                label-for="input-name"
              >
                <b-form-input
                  id="input-name"
                  v-model="form.displayName"
                  name="input-name"
                  placeholder="Enter name"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-name-live-feedback"
                />
                <b-form-invalid-feedback id="input-name-live-feedback">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-form-group
              description="Unset this checkbox to select a parent organization unit."
            >
              <b-form-checkbox
                v-model="asFirstLevel"
              >
                First level
              </b-form-checkbox>
            </b-form-group>

            <b-form-group
              v-if="!asFirstLevel"
              label="Parent:"
              label-for="input-parent"
            >
              <v-jstree
                :data="ousTreeview"
                show-checkbox
                size="large"
                whole-row
                text-field-name="displayName"
                value-field-name="id"
                children-field-name="children"
                @item-click="itemClick"
              />

            </b-form-group>
          </b-overlay>

        </b-modal>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>

import {
  BModal, BForm, BFormInput, BFormGroup, BFormCheckbox, BFormInvalidFeedback, BOverlay,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import {
  // eslint-disable-next-line no-unused-vars
  required, email, max, mapServerFieldValidationErrors,
} from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import VJstree from 'vue-jstree'

import OuService from '@/service/ou.service'

export default {
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormInvalidFeedback,
    ValidationObserver,
    ValidationProvider,
    BOverlay,
    VJstree,
  },
  props: {
    impersonateAsTenantId: {
      type: String,
      default: null,
    },
    organizationUnits: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: false,
      isLoading: false,
      isSaving: false,
      form: {
        displayName: '',
      },
      asFirstLevel: true,
      selectedOu: null,
    }
  },
  computed: {
    ousTreeview() {
      // Unflatten array
      // https://stackoverflow.com/a/55241491
      const nest = (items, id = null) => items
        .filter(item => item.parentId === id)
        .map(item => ({ ...item, children: nest(items, item.id), opened: true }))

      return nest(this.organizationUnits)
    },
    selectedParentId() {
      if (this.asFirstLevel === true) {
        return null
      }

      return this.selectedOu.id
    },
  },
  methods: {
    resetModal() {
      this.isLoading = false
      this.isSaving = false

      this.form = {
        displayName: '',
        parentId: null,
      }
      this.asFirstLevel = true
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    itemClick(node) {
      this.selectedOu = node.model
    },
    async save(event) {
      event.preventDefault()

      const self = this
      this.isSaving = true

      const requestConfig = {
        impersonateAsTenant: this.impersonateAsTenantId,
      }

      if (this.asFirstLevel === false && this.selectedOu === null) {
        self.$swal({
          title: 'Please select a parent organization unit.',
          icon: 'warning',
        })

        return
      }

      try {
        const postData = this.form
        postData.parentId = this.selectedParentId

        const response = await OuService.createAsync(postData, requestConfig)

        self.$emit('ouCreated', {
          ou: response.data,
        })

        self.$toast.success('Organization unit has been created.', {
          icon: true,
        })

        self.show = false
      } catch (e) {
        const errors = mapServerFieldValidationErrors(e.response, {
          displayName: 'DisplayName',
        })

        self.$refs.observer.setErrors(errors)

        self.$swal({
          title: 'Something went wrong!',
          text: e.response.data.error.message,
          icon: 'error',
        }).then(() => {
          self.show = true
        })
      } finally {
        self.isSaving = false
      }
    },
  },
}
</script>
