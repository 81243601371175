// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'
import { buildAxiosRequestConfig } from './serviceManager'

export default {
  async getAllListAsync(config) {
    const pageSize = 1000
    let page = 0
    let totalCount = 0

    const result = []

    do {
      // eslint-disable-next-line no-await-in-loop
      const response = await axios.get(`/core/users?MaxResultCount=${pageSize}&SkipCount=${page * pageSize}`, buildAxiosRequestConfig(config))
      totalCount = response.data.totalCount
      result.push(...response.data.items)

      page += 1
    } while (result.length < totalCount)

    return result
  },

  async getListByAccessableOusAsync(params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params

    const response = await axios.get('/core/users/accessable-ous', requestConfig)
    return response
  },

  async getAsync(id, config) {
    const response = await axios.get(`/core/users/${id}`, buildAxiosRequestConfig(config))
    return response
  },

  async getUserRolesAsync(userId, config) {
    const response = await axios.get(`/core/users/${userId}/roles`, buildAxiosRequestConfig(config))
    return response
  },

  async updateUserRolesAsync(userId, data, config) {
    const response = await axios.put(`/core/users/${userId}/roles`, data, buildAxiosRequestConfig(config))
    return response
  },

  async getAssignableRolesAsync(config) {
    const response = await axios.get('/core/users/assignable-roles', buildAxiosRequestConfig(config))
    return response
  },

  async updateOusAsync(userId, data, config) {
    const response = await axios.put(`/core/users/${userId}/organization-units`, data, buildAxiosRequestConfig(config))
    return response
  },

  async triggerOldPortalUserSynchronization(config) {
    const result = await axios.post('/core/users/trigger/synchronize-oldportalusers', {}, buildAxiosRequestConfig(config))
    return result.data
  },

  async updatePermissionsAsync(userId, data, config) {
    const response = await axios.put(`/core/users/${userId}/permissions`, data, buildAxiosRequestConfig(config))
    return response
  },

  async getUserPermissionsAsync(userId, config) {
    const response = await axios.get(`/core/permission?providerName=U&providerKey=${userId}`, buildAxiosRequestConfig(config))
    return response
  },

  async deleteAsync(id, config) {
    const response = await axios.delete(`/core/users/${id}`, buildAxiosRequestConfig(config))
    return response
  },

  async createAsync(data, config) {
    const response = await axios.post('/core/users', data, buildAxiosRequestConfig(config))
    return response
  },

  async enableTwoFactorAuthentication(userId, config) {
    const response = await axios.post(`/core/users/${userId}/2fa/enable`, {}, buildAxiosRequestConfig(config))
    return response
  },

  async disableTwoFactorAuthentication(userId, config) {
    const response = await axios.post(`/core/users/${userId}/2fa/disable`, {}, buildAxiosRequestConfig(config))
    return response
  },

  async resetTwoFactorAuthentication(userId, config) {
    const response = await axios.post(`/core/users/${userId}/2fa/reset`, {}, buildAxiosRequestConfig(config))
    return response
  },

  async unlock(userId, config) {
    const response = await axios.post(`/core/users/${userId}/unlock`, {}, buildAxiosRequestConfig(config))
    return response
  },
}
