<template>
  <b-card
    no-body
  >
    <b-card-header>
      <b-card-title>Organization units</b-card-title>

      <b-button-group>
        <b-button
          v-if="$can('__special_managePermission')"
          variant="primary"
          size="sm"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span
            v-b-modal.create-ou-modal
            class="align-middle"
          >
            Add
          </span>
          <create-ou-modal
            :impersonate-as-tenant-id="tenantId"
            :organization-units="ous"
            @ouCreated="onOuCreated"
          />
        </b-button>
        <b-button
          v-if="$can('__special_managePermission') && ous.length > 0"
          variant="outline-secondary"
          :to="{ name: 'manage:tenant-manage', params: { id: tenantId} }"
          size="sm"
        >
          <span class="align-middle">
            Manage
          </span>
        </b-button>
      </b-button-group>
    </b-card-header>
    <b-card-body
      style="max-height: 600px; overflow: auto"
    >
      <edit-ou-modal
        ref="editOuModal"
        :impersonate-as-tenant-id="tenantId"
        :organization-units="ous"
        @ouUpdated="onOuUpdated"
      />

      <p v-if="$can('Core.OrganizationUnits') && ous.length === 0">
        No organization units defined. Add organization unit to manage company structure.
      </p>

      <p
        v-if="$can('Core.OrganizationUnits') === false"
        class="text-warning"
      >
        No access to view this data.
      </p>

      <v-jstree
        :data="ousTreeview"
        size="large"
        multiple
        whole-row
        text-field-name="displayName"
        value-field-name="id"
        children-field-name="children"
        draggable
        @item-drop-before="onItemDropBefore"
      >
        <template slot-scope="_">
          <div
            style="display: inherit;"
            @click.ctrl="customItemClickWithCtrl"
          >
            <i
              v-if="!_.model.loading"
              :class="_.vm.themeIconClasses"
              role="presentation"
            />
            {{ _.model.displayName }}

            <feather-icon
              v-if="$can('__special_managePermission')"
              icon="EditIcon"
              class="treeview-item-edit"
              size="14"
              @click="openEditOuModal(_.model)"
            />

            <feather-icon
              v-if="$can('__special_managePermission')"
              icon="DeleteIcon"
              class="treeview-item-delete"
              size="14"
              @click="deleteOu(_.model)"
            />
          </div>
        </template>
      </v-jstree>

    </b-card-body>
  </b-card>
</template>

<script>

import {
  BCard, BCardHeader, BCardBody, BCardTitle, BButton, BButtonGroup,
} from 'bootstrap-vue'
import VJstree from 'vue-jstree'
import CreateOuModal from './CreateOuModal.vue'
import EditOuModal from './EditOuModal.vue'
import OuService from '@/service/ou.service'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BButton,
    BButtonGroup,
    VJstree,
    CreateOuModal,
    EditOuModal,
  },
  props: {
    tenantId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      ous: [],
    }
  },
  computed: {
    ousTreeview() {
      // Unflatten array
      // https://stackoverflow.com/a/55241491
      const nest = (items, id = null) => items
        .filter(item => item.parentId === id)
        .map(item => ({ ...item, children: nest(items, item.id), opened: true }))

      return nest(this.ous)
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      OuService.getAllListAsync({ impersonateAsTenant: this.tenantId }).then(result => {
        this.ous = result.data.items
      })
    },
    onOuCreated(e) {
      this.ous.push({
        id: e.ou.id,
        parentId: e.ou.parentId,
        displayName: e.ou.displayName,
      })
    },
    onOuUpdated(e) {
      const foundIndex = this.ous.findIndex(x => x.id === e.ou.id)
      this.ous[foundIndex].displayName = e.ou.displayName
      this.ous[foundIndex].parentId = e.ou.parentId
    },
    openEditOuModal(ou) {
      this.$refs.editOuModal.show(ou.id)
    },
    // eslint-disable-next-line no-unused-vars
    onItemDropBefore(node, item, draggedItem, e) {
      this.$toast.warning('Drag and drop is currently not supported. The changes are not saved.', {
        icon: true,
      })
    },
    deleteOu(ou) {
      const self = this
      this.$swal({
        title: 'Are you sure?',
        text: `Do you really want to delete the organization unit '${ou.displayName}'?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          OuService.deleteAsync(ou.id, { impersonateAsTenant: this.tenantId })
            .then(
              () => {
                self.ous = self.ous.filter(item => item.id !== ou.id)
                this.$toast.success('Deleted!', {
                  icon: true,
                })
              },
              e => {
                self.$swal({
                  title: 'Something went wrong!',
                  text: e.response.data.error.message,
                  icon: 'error',
                })
              },
            )
        }
      })
    },
  },
}

</script>

<style scoped>
  .treeview-item-edit {
    margin-left: 10px;
    cursor: pointer;
    color: var(--secondary);;
  }

  .treeview-item-delete {
    margin-left: 10px;
    cursor: pointer;
    color: var(--danger);;
  }
</style>
