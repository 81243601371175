<template>
  <b-card
    no-body
  >
    <b-card-header>
      <b-card-title>Security Roles</b-card-title>

      <b-button
        v-if="$can('__special_managePermission')"
        v-b-modal.create-role-modal
        variant="primary"
        size="sm"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-50"
        />
        <span class="align-middle">Add role</span>
        <create-role-modal
          :impersonate-as-tenant-id="tenantId"
          @roleCreated="onRoleCreated"
        />

      </b-button>
    </b-card-header>
    <b-card-body
      style="max-height: 400px; overflow: auto"
    >
      <edit-role-modal
        ref="editRoleModal"
        :impersonate-as-tenant-id="tenantId"
        @roleUpdated="onRoleUpdated"
      />

      <p v-if="$can('Core.Roles') && rolesToDisplay.length === 0">
        No roles defined. Add role to activate role based access.
      </p>

      <p
        v-if="$can('Core.Roles') === false"
        class="text-warning"
      >
        No access to view this data.
      </p>

      <table
        class="table"
      >
        <tbody>
          <tr
            v-for="role in rolesToDisplay"
            :key="role.id"
          >
            <td>
              {{ role.name | renameRoleName }}
            </td>
            <td>
              <b-button-group
                v-if="$can('__special_managePermission')"
                size="sm"
                class="float-right"
              >
                <b-button
                  title="Edit"
                  @click="openEditRoleModal(role)"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="18"
                  />
                </b-button>

                <b-button
                  title="Delete"
                  variant="danger"
                  @click="deleteRole(role)"
                >
                  <feather-icon
                    icon="DeleteIcon"
                    size="18"
                  />
                </b-button>
              </b-button-group>
            </td>
          </tr>
        </tbody>
      </table>
    </b-card-body>
  </b-card>
</template>

<script>

import {
  BCard, BCardHeader, BCardBody, BCardTitle, BButton, BButtonGroup,
} from 'bootstrap-vue'

import RoleService from '@/service/role.service'
import CreateRoleModal from './CreateRoleModal.vue'
import EditRoleModal from './EditRoleModal.vue'

export default {
  components: {
    BCard, BCardHeader, BCardBody, BCardTitle, BButton, BButtonGroup, CreateRoleModal, EditRoleModal,
  },
  props: {
    tenantId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      roles: [],
    }
  },
  computed: {
    rolesToDisplay() {
      // Sort roles case insensitive by name
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties, no-nested-ternary
      return this.roles
        .filter(x => !x.isStatic)
        .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }))
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    openEditRoleModal(role) {
      this.$refs.editRoleModal.show(role.id)
    },
    onRoleCreated(e) {
      this.roles.push({
        id: e.role.id,
        name: e.role.name,
      })
    },
    onRoleUpdated(e) {
      const foundIndex = this.roles.findIndex(x => x.id === e.role.id)
      this.roles[foundIndex].name = e.role.name
    },
    loadData() {
      RoleService.getAllListAsync({ impersonateAsTenant: this.tenantId }).then(result => {
        this.roles = result.data.items
      })
    },
    deleteRole(role) {
      const self = this
      this.$swal({
        title: 'Are you sure?',
        text: `Do you really want to delete the role '${role.name}'?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          RoleService.deleteAsync(role.id, { impersonateAsTenant: this.tenantId })
            .then(
              () => {
                self.roles = self.roles.filter(item => item.id !== role.id)
                this.$toast.success('Deleted!', {
                  icon: true,
                })
              },
              e => {
                self.$swal({
                  title: 'Something went wrong!',
                  text: e.response.data.error.message,
                  icon: 'error',
                })
              },
            )
        }
      })
    },
  },
}

</script>
